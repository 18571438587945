<template>
  <div />
</template>

<script>
  export default {
    mounted () {
      this.$toast.success('Logout realizado com sucesso')
      this.$store.dispatch('logout')
    },
  }
</script>
